body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.directory {
  /* background: #242322; */
  font-family: monospace;
  font-size: 16px;
  color: black;
  user-select: none;
  padding: 20px;
  border-radius: 0.4em;
}

.directory .tree,
.directory .tree-node,
.directory .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 5px;
}

.directory .tree-branch-wrapper,
.directory .tree-node__leaf {
  outline: none;
  outline: none;
}

.directory .tree-node {
  cursor: pointer;
}

.directory .tree-node:hover {
  background: white;
}

.directory .tree .tree-node--focused {
  background: rgba(255, 255, 255, 0.2);
}

.directory .tree .tree-node--selected {
  background: rgba(48, 107, 176);
  color: white;
}

.directory .tree-node__branch {
  display: block;
}

.directory .icon {
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 10px;
  size: 20px;
}
.checkbox {
  font-size: 16px;
  user-select: none;
  min-height: 320px;
  padding: 20px;
  box-sizing: content-box;
}

.checkbox .tree,
.checkbox .tree-node,
.checkbox .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.checkbox .tree-branch-wrapper,
.checkbox .tree-node__leaf {
  outline: none;
}

.checkbox .tree-node {
  cursor: pointer;
}

.checkbox .tree-node .name:hover {
  background: rgba(0, 0, 0, 0.1);
}

.checkbox .tree-node--focused .name {
  background: rgba(0, 0, 0, 0.2);
}

.checkbox .tree-node {
  display: inline-block;
}

.checkbox .checkbox-icon {
  margin: 0 5px;
  vertical-align: middle;
}

.checkbox button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.checkbox .arrow {
  margin-left: 5px;
  vertical-align: middle;
}

.checkbox .arrow--open {
  transform: rotate(90deg);
}
